import { ReactNode } from 'react';
import styles from './MatchTable.module.scss';
import { useTranslations } from 'cupman-utils';
import { Match } from '../../api/schema';
import { useFormatDate } from 'cupman-utils/lib/LangContext';
import classNames from 'classnames';
import Flag from '../Flag/Flag';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCurrentTournament } from '../../App';
import { faCirclePlay } from '@fortawesome/pro-solid-svg-icons';
import { gameLiveStatusClassName } from '../Game/Game';

type Props = {
  children: ReactNode
}

export default function MatchTable ({children} : Props) {

  const T = useTranslations('cmresults');
  
  return <div className={styles.table_overflow}>
    <table className={styles.match_table}>
      <thead>
        <tr>
          <th>{T('Matchnr.')}</th>
          <th>{T('Tid')}</th>
          <th>{T('Spelplan')}</th>
          <th>{T('Klass')}</th>
          <th>{T('Grupp')}</th>
          <th>{T('Omgång')}</th>
          <th className={styles.right}>{T('Hemma')}</th>
          <th></th>
          <th></th>
          <th></th>
          <th>{T('Borta')}</th>
          {/* <th></th> */}
        </tr>
      </thead>
      <tbody>
        {children}
      </tbody>
    </table>
  </div>
}

MatchTable.fragment = Match.fragment(
  {
    division: {
      category: {},
      stage: {}
    },
    arena: {},
    home: {
      team: {
        club: {
          nation: {}
        }
      }
    },
    away: {
      team: {
        club: {
          nation: {}
        }
      }
    },
    result: {},
    video: {}
  }
);


export function TableRow ({match} : {match: Match<typeof MatchTable.fragment>}) {
  
  const t = useCurrentTournament();
  const formatDate = useFormatDate(t.cup.timeZoneId)
  const T = useTranslations('cmresults');

  const provider = t.streamingInfo?.provider;
  const streamingProviderColor = provider === 'solidsport'
    ? 'rgb(var(--clr-solidsport))'
    : provider === 'handbollplay'
      ? 'rgb(var(--clr-handbollplay))'
      : 'black'

  const winner = match.result.homeState === "win" ? 'home'
    : match.result.awayState === "win" ? 'away'
    : undefined;

  return <tr className={classNames(gameLiveStatusClassName({
    finished: match.finished,
    live: match.live,
    externalVideoLink: !!match.video && !!match.video.externalLink
  }),)}>
    <td>
      <div className={styles.streaming_icon}>
        <Link to={`/match/${match.id}`} style={{'--streaming-color' : streamingProviderColor} as any} aria-label={T('Streamed by X', provider)}>
          <span>{match.matchNr ? match.matchNr : '-'}</span>
          <span>{match.video && <FontAwesomeIcon icon={faCirclePlay} />}
          </span>
        </Link>
      </div>
    </td>
    <td>
      <Link to={`/match/${match.id}`}>{match.start > 0 ? formatDate(match.start, 'EEE. dd/MM HH:mm') : '-'}</Link>
    </td>
    <td>
      <Link to={`/match/${match.id}`}>{match.arena ? match.arena.name : '-'}</Link>
    </td>
    <td>
      <Link to={`/match/${match.id}`}>{match.division.category.name}</Link>
    </td>
    <td>
      <Link to={`/match/${match.id}`}>{T.select(match.division.name)}</Link>
    </td>
    <td>
      <Link to={`/match/${match.id}`}>{match.division.stage.name}</Link>
    </td>
    <td>
      <Link to={`/match/${match.id}`} className={classNames(styles.right, styles.team, winner === 'home' && styles.bold)}>
        <span>{T.select(match.home.name)}</span>
        <Flag code={match.home.team?.club.nation.code} alt={T.select(match.home.team?.club.nation.name) || ''} />
      </Link>
    </td>
    
    {(match.live || match.finished ) && !match.division.category.hideMatchResults ? <>
      {match.result.presentation === 'Result' ? <>
        <td className={classNames(styles.no_padding, styles.right, winner === 'home' && styles.bold)}>
        <Link to={`/match/${match.id}`}>{match.result.homeGoals}</Link>
        </td>
        <td>
          <Link to={`/match/${match.id}`}>-</Link>
        </td>
        <td className={classNames(styles.no_padding, winner === 'away' && styles.bold)}>
          <Link to={`/match/${match.id}`}>{match.result.awayGoals}</Link>
        </td>
      </> : match.result.presentation === 'Points' ? <>
        <td className={classNames(styles.no_padding, styles.right, winner === 'home' && styles.bold)}>
          <Link to={`/match/${match.id}`}>{match.result.homePoints}</Link>
        </td>
        <td>
          <Link to={`/match/${match.id}`}>-</Link>
        </td>
        <td className={classNames(styles.no_padding, winner === 'away' && styles.bold)}>
          <Link to={`/match/${match.id}`}>{match.result.awayPoints}</Link>
        </td>
      </> : 
        <td colSpan={3}>
          {match.result.presentation === 'ResultsPerSet' && match.result.periodScores.map((ps, i) => {
            return <Link to={`/match/${match.id}`}>
              <span className={classNames(ps.homeGoals > ps.awayGoals && styles.bold)}>{ps.homeGoals}</span> 
              <span>-</span>
              <span className={classNames(ps.awayGoals > ps.homeGoals && styles.bold)}>{ps.awayGoals}</span>
              {i < match.result.periodScores.length-1 && <span className={styles.period_separator}>/</span>}
            </Link>
          })}
        </td>
      }
    </> : <td colSpan={3}></td>}


    <td>
    <Link to={`/match/${match.id}`} className={classNames(styles.team, styles.team_away, winner === 'away' && styles.bold)}>
      <Flag code={match.away.team?.club.nation.code} alt={T.select(match.away.team?.club.nation.name) || ''} />
        <span>{T.select(match.away.name)}</span>
      </Link>
    </td>
    {/* <td>
      <Link to={`/match/${match.id}`} className={styles.match_link}>
        <FontAwesomeIcon icon={faArrowRight} />
        <span className="visually-hidden">{T('Gå till match: X', `${T.select(match.home.name)} - ${T.select(match.away.name)}`)}</span>
      </Link>
    </td> */}
  </tr>
}