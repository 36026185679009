import { faCity } from "@fortawesome/pro-regular-svg-icons"
import { faArrowRightFromBracket, faArrowRightToBracket, faCircleInfo, faGlobe, faLocationDot, faMap, faMapLocationDot, faRoute } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { useTranslations } from "cupman-utils"
import { useLink } from "granular"
import { Suspense, useEffect, useRef } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Arena, EditionDay, MapLocation, useAddress } from "../../api/schema"
import { useCurrentTournament } from "../../App"
import { useAdmin } from "../../components/AdminBanner/AdminBanner"
import LinkButton from "../../components/Button/LinkButton"
import ContentContainer from "../../components/ContentContainer/ContentContainer"
import CustomIconFa from "../../components/CustomIconFa/CustomIconFa"
import Games from "../../components/Game/Games"
import { header, list, LoadingContent, LoadingMatches, paragrapgh } from "../../Loading/LoadingContent"
import PoiCard from "../../Map/PoiCard"
import { setModalNoScroll } from "../../utils"
import { useRefScrolledFromTop } from "../../utils/HasScrolledFromTop"
import styles from './MapOverlayArena.module.scss';
import { ArenasAndDays } from "./PlacesPage"
import HeaderSelect from "../../components/HeaderSelect/HeaderSelect"

type locationProps = {
  arena: Arena<{
    location: {
      location: {}
    },
    days: [{}]
  }>,
  arenas: Arena[],
  setScrolled: (scrolled: boolean) => void, 
  scrolled?: boolean,
  editionDays: EditionDay[],
  pointsOfInterest?: (MapLocation & {distance:number})[],
  onChange: (arenaId:number) => void
}


export default function MapOverlayArena ({arena, arenas, editionDays, pointsOfInterest, setScrolled, scrolled, onChange} : locationProps) {
  
  useEffect(() => {
    setModalNoScroll(true);
    return () => setModalNoScroll(false);
  })

  return <div className={classNames(styles.info)}>
    
    
    <Suspense fallback={
      <div>
        <LoadingContent className="max-50" margin={20} components={
          [header(30, 25), list(37,50, 1)]
        }/>
        <LoadingMatches/>
      </div>
    }>
      
      <MapOverlayArenaInner arena={arena} onChange={onChange} arenas={arenas} editionDays={editionDays} pointsOfInterest={pointsOfInterest} setScrolled={setScrolled} scrolled={scrolled}/>
      
  </Suspense>
  </div>
}


function MapOverlayArenaInner ({arena : _arena, arenas, pointsOfInterest, setScrolled, scrolled, onChange} : locationProps) {

  const T = useTranslations('cmresults');
  const arena = useLink(_arena, Arena.fragment({
    location: {
      location: {}
    },
    days: [{
      matches: [Games.GameFragment]
    }]
  }));

  const t = useCurrentTournament();
  const sport = t.subcup.sport.type;
  const container = useRef(null as null | HTMLDivElement);
  const admin = useAdmin();


  const params = useParams<{arenaId: string, dayId: string}>()

  useRefScrolledFromTop(container.current, setScrolled);

  useEffect(()=>{
    if( container.current ){

      const dayContainer = document.getElementById("matchesForDayId_" + params.dayId);

      const top = dayContainer
        ? dayContainer.offsetTop
        : 0

      container.current && container.current.scrollTo({
        left:0, 
        top: top - 25,
        behavior: 'smooth'
      ,});
    }
    
  }, [arena.id, params.dayId, container.current]);

 
  const mapPublished = t.mapPublished || admin.overridePublished;
  

  return <div style={{maxWidth: "100%"}}>
    <div className={styles.top_shadow}></div>
    <div className={styles.map_overlay_content_container} ref={container}>
      <div>
        <div className="max-50">

          <div>
            <h4 className={classNames(styles.adress,"custom-font")}>
              <span><FontAwesomeIcon icon={faLocationDot}/> 
              {arena.location.location.street}, {arena.location.location.postal}</span>
              <span><FontAwesomeIcon icon={faCity}/>
              {arena.location.location.city}</span>
            </h4>
            {((pointsOfInterest && pointsOfInterest?.length > 0) || arena.location.description) && <>
              {/*<h3 className={styles.more_margin}>
                <FontAwesomeIcon icon={faCircleInfo}/> {T('Information')}
              </h3>
            <ContentContainer>*/}

                {arena.location.description && 
                  <div className={styles.inner_info} dangerouslySetInnerHTML={{__html: T.select(arena.location.description)}}>
                  </div>
                } 
                {arena.location.toDescription && <>
                  <h4 className={classNames(styles.info_header,"custom-font")}>
                    <FontAwesomeIcon icon={faArrowRightToBracket}/> {T('Travel to X', arena.location.name)}
                  </h4>
                  <div className={styles.inner_info} dangerouslySetInnerHTML={{__html: T.select(arena.location.toDescription)}}></div>
                </>}
                {arena.location.fromDescription && <>
                  <h4 className={classNames(styles.info_header,"custom-font",styles.directions_title)}>
                    <FontAwesomeIcon icon={faArrowRightFromBracket}/> {T('Travel from X', arena.location.name)}
                  </h4>
                  <div className={styles.inner_info} dangerouslySetInnerHTML={{__html: T.select(arena.location.fromDescription)}}></div>
                </>}
                {pointsOfInterest && pointsOfInterest.length > 0 && <>
                  <h4 className={classNames(styles.info_header,"custom-font")}>
                    <FontAwesomeIcon icon={faMapLocationDot}/> {T('Interesting place(s):Y close to X', arena.location.name, pointsOfInterest.length)}
                  </h4>
                  <div className={styles.points_of_interest}>
                    {pointsOfInterest.map(point => <PoiCard point={point} startPoint={arena.location.location} />)}
                  </div>
                </>}

              {/*</ContentContainer>*/}
            </>}
            
          </div>
        </div>
        <div className={classNames(styles.place_nav_links, scrolled && styles.scrolled, !mapPublished && styles.no_map)}>
  
          <LinkButton size="lg" className={styles.nav_link} url={`https://www.google.com/maps/dir/?api=1&destination=${arena.location.location.latitude},${arena.location.location.longitude}`}>
            <FontAwesomeIcon icon={faRoute} /> <span className={styles.btn_txt}>{T('Directions')}</span>
            <div className={styles.link_shadow}>
              <div></div>
            </div>
          </LinkButton>
          <LinkButton size="lg" className={classNames(styles.nav_link, styles.optional)} url={`https://www.google.com/maps/search/?api=1&query=${arena.location.location.latitude},${arena.location.location.longitude}`}>
            <FontAwesomeIcon icon={faGlobe}/> <span className={styles.btn_txt}>{T('Show on Google maps')}</span>
            <div className={styles.link_shadow}>
              <div></div>
            </div>
          </LinkButton>
        </div>
        {/*<div className={classNames(styles.more_margin)}>
          <ArenasAndDays days={t.days} arenas={arenas} admin={admin}/>
                </div>*/}
        <div className={classNames(styles.more_margin)}>
          <h3 className={"custom-font"}>
            <CustomIconFa icon={`field-${sport}`} fontWeight='bold' />
            {arenas.length === 1 ? 
              T('Games in X', arena.name)
             : <><span>{T('Games in')}</span><HeaderSelect 
             onChange={onChange}
             selectedId={arena.id} 
             options={arenas}/></>}
          </h3>

          

          <div className={styles.games_wrapper}>
            {arena.days.map(day => {
              return <div id={`matchesForDayId_${day.editionDayId}`} key={day.editionDayId} className={styles.day_matches}>
                <Games published={t.schedulePublished || admin.overridePublished} games={day.matches} order="asc" alwaysShow={params.dayId === day.editionDayId+""}/>
              </div>
            })}
          </div>
        </div>
      </div>
    </div>
  </div>
}