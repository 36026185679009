import { faAngleRight, faTableList } from '@fortawesome/pro-regular-svg-icons';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useTranslations } from 'cupman-utils';
import { useFormatDate } from 'cupman-utils/lib/LangContext';
import { useLink } from 'granular';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Arena, EditionDay, Tournament$days } from '../../api/schema';
import { useCurrentTournament } from '../../App';
import { useAdmin } from '../../components/AdminBanner/AdminBanner';
import MiddleEllipsisSpan from '../../components/MiddleEllipsisSpan/MIddleEllipsisSpan';
import { ErrorBoundary } from '../../Error/ErrorBoundary';
import ErrorComponent from '../../Error/ErrorComponent';
import MapWithOverlay from '../../Map/MapWithOverlay';
import useCatchBodyScroll from '../../useBodyScroll';
import { groupBy } from '../../utils';
import { getPointsOfInterest } from '../MapPage/MapPage';
import MapOverlayArena from './MapOverlayArena';
import styles from './PlacesPage.module.scss';
import LinkButton from '../../components/Button/LinkButton';

export const makeUpperCaseFirst = (txt: string) => txt.charAt(0).toUpperCase() + txt.slice(1);

export default function PlacesPage() {
  const T = useTranslations("cmresults");
  const admin = useAdmin(); 

  const t = useLink(useCurrentTournament(), {
    arenas:[
      {
        days: [{
        }],
        location: {
          location: {}
        }
      }
    ],
    days: [{}],
    locations: []
  });

  const scrolling = useCatchBodyScroll();

  const arenaLocations = Object.values(groupBy(t.arenas
          .filter(arena => arena.days.some(day => day.matchCount > 0))
          .map(arena => ({...arena.location.location, arena:arena})),
        location => ''+location.id)).map(locations => locations[0]);
  

  const arenas = t.arenas.filter(arena => arena.days.some(day => day.matchCount > 0));


  const [map, setMap] = useState<any>(undefined);
  const [hasSelection, setHasSelection] = useState<boolean>(false);
  const [selectedArena, setSelectedArena] = useState<Arena<{
    location: {
      location: {}
    },
    days: [{
    }]
  }> | undefined>(undefined);
  const [overlayScrolled, setOverlayScrolled] = useState<true|false|undefined> (undefined);
  
  const locationArenas = arenas.filter(a => a.location.id === selectedArena?.location.id);


  
  const pointsOfInterest = !hasSelection 
    ? undefined
    : selectedArena && getPointsOfInterest(t.locations, selectedArena.location.location);

  const m = useParams<{arenaId:string, dayId:string}>();

  const paramArena = m
    ? arenas.find(arena => m && arena.id === parseInt(
      m.arenaId || '0'
      )
    )
    : undefined


  useEffect(() => {
    //if(map) {
      
      if (hasSelection && selectedArena) {
        if(paramArena?.id === selectedArena.id) {
          setHasSelection(false);
          setOverlayScrolled(undefined);
        } else if( paramArena ){
          setSelectedArena(paramArena);
          setHasSelection(true);
          setOverlayScrolled(undefined);
        } else {
          setHasSelection(false);
          setOverlayScrolled(undefined);
        }
      } else if( paramArena ){
        setSelectedArena(paramArena);
        setHasSelection(true);
        setOverlayScrolled(undefined);
      }
    //}
  }, [paramArena?.id/*, map*/])


  let navigate = useNavigate();

  const selectArena = (arena : Arena | undefined) => {
    if(arena) {
      navigate('/places/' + arena.id)
    } else {
      navigate('/places')
    }
  }

  const mobile =  window.matchMedia('(hover:none)').matches;

  return <>
    <h1 className="visually_hidden">{T('Arenas')}</h1>

    {<div className={styles.sticky}>
      <ErrorBoundary fallback={({error, errorInfo}) => 
        <ErrorComponent error={error} errorInfo={errorInfo} text={T('Could not load map')} square />
      }>

      <MapWithOverlay 
        noMap={!t.mapPublished}
        hideMap={mobile && overlayScrolled }
        pointsOfInterest={(pointsOfInterest && pointsOfInterest.length > 0) ? pointsOfInterest.map(location => (
          {
            ...location,
            color: /*location.color ? location.color : */ undefined
          }
        )) : undefined}
        clusterLabel={markers => T('X arenas', markers.length)}
        setMap={setMap}
        markers={arenaLocations.map(location => (
          {
            ...location,
            color: /*location.color ? location.color : */ undefined
          }
        ))}
        scrolling={scrolling}
        minInitWidth="registrations"
        selectedId={hasSelection ? selectedArena?.location.id :  undefined}
        onChange={(location) => {
          selectArena(location?.arena)
        }}
        visible={hasSelection}
        selectedOverlay={selectedArena 
          ? <MapOverlayArena onChange={arenaId => navigate(`/places/${arenaId}`)} arena={selectedArena} arenas={locationArenas} setScrolled={setOverlayScrolled} scrolled={mobile && overlayScrolled} editionDays={t.days} pointsOfInterest={pointsOfInterest} />
          : undefined}
        selectedTitle={
          <div className={classNames(styles.locationName,"max-75")}>
            <Link to={"/places"}>{T('Arenas')}</Link>
            <FontAwesomeIcon icon={faAngleRight} className={styles.arrow} />
            <h1 className="custom-font">
            <img src={`https://static.cupmanager.net/images/icons_svg/marker-symbols/arena.svg`} 
                className={styles.header_icon} alt="" /> 
            <MiddleEllipsisSpan inline text={selectedArena?.name||''}/>
            </h1>
          </div>

        }
      />
      </ErrorBoundary>
    </div>}


    {/*<div className={styles.shadow_below}></div>*/}
    <ErrorBoundary fallback={({error, errorInfo}) => 
      <div className="max-75" style={{marginTop: '4rem'}}>
        <ErrorComponent error={error} errorInfo={errorInfo} text={T('Could not load map')} />
      </div>
    }>
      <ArenasAndDays days={t.days} arenas={arenas} admin={admin}/>
    </ErrorBoundary>
  </>
  
}

export function ArenasAndDays({days, arenas, admin} : {days: EditionDay<{}>[], arenas: Arena<{
  days: [{}];
  location: {
      location: {};
  };
}>[], admin: {
  isAdmin: boolean;
  overridePublished: boolean;
  setOverridePublished: (override: boolean) => void;
}}){

  const t = useCurrentTournament();
  const T = useTranslations('cmresults')
  const formatDate = useFormatDate(t.cup.timeZoneId);

  return <>
  <div className={classNames("max-50", styles.places_grid)}>
      <div className={styles.desktop_wrapper}>
        <table className={styles.desktop_table}>
          <thead>
            <tr>
              <td className={styles.place}></td>
              {days.map(day => <td key={day.id} className={styles.date_header}>
                  <span>
                    {makeUpperCaseFirst(formatDate(day.date, 'eee d/M'))}
                  </span>
                </td>
              )}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={days.length + 1} className={styles.thead_space}></td>
            </tr>
          </tbody>

          <tbody className={styles.places_body}>
            {arenas.map(arena => {

              
              return <>
                <tr>
                  <td colSpan={days.length + 2}>
                    <hr />
                  </td>
                </tr>
                <tr className={styles.place_row}>
                  <td className={styles.place}>
                    <Link to={`${arena.id}`} className={styles.arena_link}>
                    {arena.name}
                    </Link>
                  </td>
                  {days.map(day => <td key={day.id} className={styles.date}>
                      {(arena.days.find(ad => ad.editionDayId===day.id)?.matchCount||0) > 0 && <Link className={classNames(styles.btn_link, !day.schedulePublished && !admin.overridePublished && styles.disabled)} to={`${arena.id}/${day.id}`}>
                        {formatDate(day.date, 'd/M')}
                      </Link>}
                    </td>
                  )}
                  <td>
                    <Link to={`/games/?arena=${arena.id}`} className={styles.all_games_link}>
                      {T('Alla matcher')}
                    </Link>
                  </td>
                </tr>
              </>
            })}
          </tbody>
        </table>
      </div>

      <div className={styles.mobile_wrapper}>
        {arenas.map(arena => <div className={styles.place_card} key={arena.id}>
          <div className={styles.mobile_header}>
            <span>{arena.name}</span>
            <Link to={`/map/${arena.location.id}/${arena.id}`} className={styles.info_link}>
              <FontAwesomeIcon icon={faCircleInfo} className={styles.icon} /> Info
            </Link>
            <Link to={`/games/?arena=${arena.id}`} className={styles.info_link}>
              {T('Alla matcher')}
            </Link>
          </div>
          <div className={styles.mobile_body}>
            <div className={styles.dates}>
              {days.map(day => (arena.days.find(ad => ad.editionDayId===day.id)?.matchCount||0) > 0 && <div key={day.id}>
                <Link className={classNames(!day.schedulePublished && !admin.overridePublished && styles.disabled)} to={`${arena.id}/${day.id}`}>{makeUpperCaseFirst(formatDate(day.date, 'eee d/M'))}</Link>
              </div>
            )}
            </div>
          </div>
        </div>)}
      </div>
    </div>
  </>
}