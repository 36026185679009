import classNames from "classnames";
import { useTranslations } from "cupman-utils";
import { Stage$StageRankingPlace } from "../../api/schema";
import Flag from "../Flag/Flag";
import WinnersIcon from "../WinnersIcon/WinnersIcon";
import styles from './WinnersAwardContainer.module.scss';
import { useFormatDate } from "cupman-utils/lib/LangContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/pro-solid-svg-icons";
import { Link } from "react-router-dom";
import { useCurrentTournament } from "../../App";

WinnersAwardContainer.Fragment = Stage$StageRankingPlace.fragment({
  team: {
    club: {
      nation: {}
    }
  },
  ___on_Stage$StageRankingPlace_MatchStatus: {
    match: {
      arena: {},
      home: {
        team: {
          club: {
            nation: {}
          }
        }
      },
      away: {
        team: {
          club: {
            nation: {}
          }
        }
      },
      roundName: {}
    }
  },
  ___on_Stage$StageRankingPlace_ConferencePlace: {
    conference: {
      matches: [{}]
    }
  }
}).___on_Stage$StageRankingPlace;

type props = {
  ranking: Stage$StageRankingPlace<typeof WinnersAwardContainer.Fragment>,
  stageName: string
}

export default function WinnersAwardContainer ({ranking, stageName} : props) {
  

  const T = useTranslations('cmresults');
  const t = useCurrentTournament();
  const formatDate = useFormatDate(t.cup.timeZoneId);

  const getDateFormat = () => {
    if('match' in ranking) {

      if(ranking.match.arena) {
        return `${ranking.match.arena.name} - ${formatDate(ranking.match.start, 'E. d LLL kk:mm')}`;
      }

      return `${formatDate(ranking.match.start, 'E. d LLL kk:mm')}`;
    }

    if ('conference' in ranking) {
      const sceduledMatches = ranking.conference.matches.filter(match => match.isScheduled);

      const theLastGame = sceduledMatches.length > 0
        ? sceduledMatches.reduce((prev, current) => prev.start > current.start ? prev : current, ranking.conference.matches[0])
        : undefined;

        if(theLastGame) {
          if (theLastGame.arena) {
            return `${theLastGame.arena} - ${formatDate(theLastGame.start, 'E. d LLL kk:mm')}`;
          }
          
          return `${formatDate(theLastGame.start, 'E. d LLL kk:mm')}`;
        }
  
      return '';
    }
  }

  const rankTxt = ranking.rank === 1
    ? {
      header: T('gold - First place'),
      color: 'rgb(var(--clr-gold))'
    }
    : ranking.rank === 2
      ? {
        header: T('silver - Second place'),
        color: 'rgb(var(--clr-silver))'
      }
      : ranking.rank === 3
        ? {
          header: T('bronze - Third place'),
          color: 'rgb(var(--clr-bronze))'
        }
        : {
          header: T('X:th place', ranking.rank),
          color: 'rgb(var(--clr-gray-500))'
        }

  const winOrLoss = () => {
    if('match' in ranking) {
      if(ranking.status === 'win') {
        return T('winner')
      }
      return T('loser')
    }
    return '';
  }

  const getRoundName = () => {
    if('match' in ranking) {
      
      return T.select(ranking.match.roundName.name);
    }
    if('conference' in ranking) {
      return T('Tableranking')
    }

    return '';
  }

  const getTeamNames = () => {
    if('match' in ranking) {
      const m = ranking.match;

      if(m.home.team || m.away.team) {
        // At least one team is defined
        if(m.home.team && !m.away.team) {

          return <>
            <span className={styles.team}>
              <Flag code={m.home.team.club.nation.code} borderColor={rankTxt.color} alt={T.select(m.home.team.club.nation.name)} />
              <span className={styles.team_name}>
                {m.home.team.name.clubName}
              </span>
            </span>
            <span>-</span>
            <span className={styles.team}>
              <span className={styles.team_name}>{T.select(m.home.name)}</span>
            </span>
          </>
        }
        if (m.away.team && !m.home.team) {

          return <>
            <span className={styles.team}>
              <span>{T.select(m.home.name)}</span>
            </span>
            <span>-</span>
            <span className={styles.team}>
              <Flag code={m.away.team.club.nation.code} alt={T.select(m.away.team.club.nation.name)} borderColor={rankTxt.color} />
              <span className={styles.team_name}>{m.away.team.name.clubName}</span>
            </span>
          </>
        }

        if (m.away.team && m.home.team) {
          return <>
            <span className={styles.team}>
              <Flag code={m.home.team.club.nation.code} borderColor={rankTxt.color} alt={T.select(m.home.team.club.nation.name)} />
              <span className={styles.team_name}>{m.home.team.name.clubName}</span>
            </span>
            <span className={styles.team}>
              <Flag code={m.away.team.club.nation.code} borderColor={rankTxt.color} alt={T.select(m.away.team.club.nation.name)} />
              <span className={styles.team_name}>{m.away.team.name.clubName}</span>
            </span>
          </>
        }

      }

      return <>
        <span className={styles.team}>
          <span className={styles.team_name}>
            {`${T.select(m.home.name)} - ${T.select(m.away.name)}`}
          </span>
        </span>
      </>
    }

    if('conference' in ranking) {
      const rankInConference = ranking.rankInConference + 1;
      return T('X:(st|nd|rd|th):X in Y',rankInConference,T.select(ranking.conference.name));
    }

    return '';
  }

  const headerTxt = ranking.team
  ? `${stageName} - ${rankTxt.header}` // A winner
  : `${stageName} - ${winOrLoss()} ${getRoundName()}` // No winner yet

  const finishedAndTop = ranking.team && ranking.rank <= 3;
  const finishedAndNotTop = ranking.team && ranking.rank > 3;

  const getPhase = () => {
    if('match' in ranking) {

      const m = ranking.match;

      if (m.finished) {
        return 'finished';
      }

      if (m.live && !m.finished) {
        return 'live'
      }
    }

    if('conference' in ranking) {
      if(ranking.team) {
        return 'finished';
      }
    }

    return 'upcoming'
  }

  const getUrl = () => {
    if ('match' in ranking) {
      return `/match/${ranking.match.id}`
    }

    if ('conference' in ranking) {
      return `/division/${ranking.conference.id}`
    }

    return '/';
  }

  const _children = <>
    <WinnersIcon placement={ranking.rank} phase={getPhase()} />
      
    <div className={styles.info}>
      <h2 className={
        classNames(
          styles.award_header,
          ranking.rank === 1 && styles.gold,
          ranking.rank === 2 && styles.silver,
          ranking.rank === 3 && styles.bronze,
          finishedAndTop && styles.bold,
          finishedAndNotTop && styles.gray,
          !ranking.team && styles.extra_margin,
          'custom-font'
        )
      }>{headerTxt}</h2>

      <div className={styles.teams_container}>
        {ranking.team
          ? /* THERE IS A WINNER*/ <>
            <span className={classNames(styles.team,
              styles.finished,
              finishedAndTop && styles.bold, finishedAndNotTop && styles.gray)}>
              <Flag code={ranking.team.club.nation.code} borderColor={rankTxt.color} alt={T.select(ranking.team.club.nation.name)} /> <span className={styles.team_name}>
                {ranking.team.name.clubName}
              </span>
            </span> 
          </>
          : /* MATCH NOT FINISHED */<>
            {getTeamNames()}
          </>
        }
      </div>
      {!ranking.team && <div className={classNames(
        styles.extra_info,
        ranking.rank === 1 && styles.gold,
        ranking.rank === 2 && styles.silver,
        ranking.rank === 3 && styles.bronze
      )}>
        {getDateFormat() && <>
          <FontAwesomeIcon icon={faClock}/> {getDateFormat()}
        </>}
      </div>}
    </div>
  </>


  return <>
  {getPhase() === 'upcoming'
    ? <div className={classNames(
      styles.wrapper,
      getPhase() !== 'finished' && styles.disabled,
      getPhase() === 'live' && styles.live
    )}>
      {_children}
    </div>
    : <Link to={getUrl()} className={classNames(
      styles.wrapper,
      getPhase() !== 'finished' && styles.disabled,
      getPhase() === 'live' && styles.live
    )}>
      {_children}
    </Link>
  }
    
  </>
}

