import { faClock, faStopwatch, faTrophy } from '@fortawesome/pro-regular-svg-icons';
import { faForkKnife, faTrain, faSun, faMoon, faPlaneArrival, faBus, faCar, faShip, faPlaneDeparture, faRoute } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import CustomIconFa from '../CustomIconFa/CustomIconFa';
import Flag from '../Flag/Flag';
import styles from './TeamTimeLine.module.scss';
import classNames from 'classnames';
import { getEventPlaceBg } from './TeamTimeLine';
import { getColorString, useColors } from '../../colors';
import { Arena, Location$MarkerImages, MatchActor, MatchLink, Referee, Team, Team$MatchEvent, Team$TimelineEvent, useMe, useTournament } from '../../api/schema';
import { useCurrentTournament } from '../../App';
import { useLink } from 'granular';
import { useTranslations } from 'cupman-utils';
import { Link } from 'react-router-dom';
import { Suspense } from "react"
import ContentLoader from 'react-content-loader';
import MiddleEllipsisSpan from '../MiddleEllipsisSpan/MIddleEllipsisSpan';
import { useFormatDate } from 'cupman-utils/lib/LangContext';
import Button from '../Button/Button';
import LinkButton from '../Button/LinkButton';
import { faAngleRight, faCartCircleCheck, faCheck, faCircleCheck, faPartyHorn, faTick } from '@fortawesome/pro-light-svg-icons';

type props = {
  entity: Team | Referee,
  eventData : Team$TimelineEvent,
  place: Location$MarkerImages,
  locationName: string,
  index: number,
  hidden: boolean,
}




export default function EventWrapper ({ eventData, entity, place, locationName, index, hidden } : props) {

  const T = useTranslations("cmresults");
  const colors = useColors();
  const t = useCurrentTournament();

  const formatDate = useFormatDate(t.cup.timeZoneId);

  
  const getContainerStyle = () => {

    if(eventData.type === 'sleep' || eventData.type === 'wake') {
      return {
        background: 'transparent',
        boxShadow: '0'
      }
    }

    if (eventData.type === 'food') {
      return {
        background: getColorString(getEventPlaceBg(colors,place), .06),
        boxShadow: `0px 5px 0px -1px ${getColorString(getEventPlaceBg(colors, place))}`
      }
    }

    if (eventData.type === 'custom') {
      return {
        background: getColorString(getEventPlaceBg(colors,place), .06),
        boxShadow: `0px 5px 0px -1px ${getColorString(getEventPlaceBg(colors, place))}`
      }
    }

    if (eventData.type === 'arrival' || eventData.type === 'departure') {
      return {
        background: getColorString(getEventPlaceBg(colors,place))
      }
    }
    
    return {
      background: getColorString(getEventPlaceBg(colors,place), .06),
      boxShadow: '0'
    }
  }



  return <div className={classNames(styles.event_outer_container, hidden && "date_hidden", (eventData.type === 'sleep' || eventData.type === 'wake') && styles.lodging, eventData.type === 'food' && styles.food)}>
    <div className={styles.event_line}>
      <div className={styles.clock} style={{background: getColorString(getEventPlaceBg(colors,place))}}>
        {(eventData.type === 'sleep' || eventData.type === 'wake') 
          ? eventData.type === 'wake'
            ? <FontAwesomeIcon icon={faSun} />
            : <FontAwesomeIcon icon={faMoon} />
          : <FontAwesomeIcon icon={faClock} />
        }
      </div>
      <div className={classNames(styles.line, styles.line_one)} style={{
        background: (eventData.type === 'arrival' || eventData.type === 'departure')
          ? index > 1
            ? '#DFEEEF'
            : 'transparent'
          : getColorString(getEventPlaceBg(colors, place))
      }}></div>
      <div className={classNames(styles.line, styles.line_two)} style={{background: getColorString(getEventPlaceBg(colors, place))}}></div>
    </div>

    <div className={styles.event_time}>
      
      {!(eventData.type === 'sleep' || eventData.type === 'wake') && <>
          <div>{formatDate(eventData.startTime, 'HH:mm')}</div>
          {eventData.startTime !== eventData.endTime && <div>{eventData.endTime && formatDate(eventData.endTime, 'HH:mm')}</div>}
        </>
      }
    </div>
    
    <div className={styles.event_content}>
      <div style={getContainerStyle()} className={styles.event_container}>

        {(eventData.type === 'food' || eventData.type === 'sleep' || eventData.type === 'wake') && <TimelineLodging eventData={eventData} />}

        {eventData.type === 'arrival' && <div className={styles.food_arrival}>
          <span className={classNames(styles.lodging_location, styles.arrivals)}>
            
            <FontAwesomeIcon icon={eventData.arrival?.method === 'Airplane' ? faPlaneArrival :
                                   eventData.arrival?.method === 'Boat' ? faShip :
                                   eventData.arrival?.method === 'Bus' ? faBus :
                                   eventData.arrival?.method === 'Car' ? faCar :
                                   eventData.arrival?.method === 'Train' ? faTrain :
                                   faRoute

            }/>
            <strong>{T('Arriving to X',locationName)}</strong>
          </span>
        </div>}

        {eventData.type === 'departure' && <div className={styles.food_arrival}>
          <span className={classNames(styles.lodging_location, styles.arrivals)}>
            
            <FontAwesomeIcon icon={eventData.arrival?.method === 'Airplane' ? faPlaneDeparture :
                                   eventData.arrival?.method === 'Boat' ? faShip :
                                   eventData.arrival?.method === 'Bus' ? faBus :
                                   eventData.arrival?.method === 'Car' ? faCar :
                                   eventData.arrival?.method === 'Train' ? faTrain :
                                   faRoute

            }/>
            <strong>{T('Departing from X',locationName)}</strong>
          </span>
        </div>}

        {eventData.type === 'game' && eventData.match && 
          <Suspense fallback={<TimelineLoadingGame/>}>
             {entity.__typename == "Referee" ? 
              <TimelineRefereeGame event={eventData.match} locationName={locationName} referee={entity} /> :
              <TimelineGame walkover={eventData.match?.isWalkOver} matchlink={eventData.match.match} locationName={locationName} />
            }
          
          </Suspense>
        }

        {eventData.type === 'custom' && eventData.custom && 
          <div className={styles.custom_event}>
             <span className={styles.lodging_location}>{T.select(eventData.custom.title)}</span>
             <span className={styles.custom_description}>{T.select(eventData.custom.description)}</span>
          </div>
        }

        {eventData.match?.pause && <div className={styles.paus}>
          <FontAwesomeIcon icon={faStopwatch} /> {T('Time to next game: X', eventData.match?.pause)}
        </div>}

      </div>
    </div>
  </div>
}



const MatchActorFragment = MatchActor.fragment({
  team: {
    club: {
      nation: {}
    }
  }
});



function TimelineLoadingGame () {
  const colors = useColors();

  return  <div  className={styles.match_link}>
      <div className={styles.match_info_container}>
        <div className={styles.teams_class}>
        <ContentLoader
            backgroundColor={'rgb(var(--clr-gray-100))'}
            foregroundColor={'rgb(var(--clr-gray-50))'}
            width="100%"
            height="80px"
          >
            <rect x="0" y="10" rx="6" ry="6" width="100%" height="25" />
            <rect x="0" y="50" rx="6" ry="6" width="100%" height="25" />
        </ContentLoader>
        </div>
      </div>
      <div className={styles.match_info} style={{background: getColorString(getEventPlaceBg(colors,'arena'))}}>
        <span className={styles.field}>
        </span>
      </div>
    </div>
}

function FieldName({locationName, arena} : {locationName:string, arena:Arena|undefined}) {
  const T = useTranslations("cmresults");
  const t = useCurrentTournament();

  const placeName = locationName.toLowerCase();
  const fieldName = arena?.name.toLowerCase() || '';

  if( !arena ){
    return <span className={styles.field}>
      <CustomIconFa white fontWeight="bold" icon={`field-${t.subcup.sport.type}`}/> 
      {T('Not scheduled')} 
    </span>
  } else if( fieldName == placeName ){
      return <span className={styles.field}></span>
  } else if(fieldName.includes(placeName)) {
    return <span className={styles.field}>
      <CustomIconFa white fontWeight="bold" icon={`field-${t.subcup.sport.type}`}/> 
      { `${T('Field')} ${fieldName.replace(placeName, '').toUpperCase().trim()}`} 
    </span>
  } else {
    return <span className={styles.field}>
      <CustomIconFa white fontWeight="bold" icon={`field-${t.subcup.sport.type}`}/> 
      {arena?.name} 
    </span>
  }
}

const TimeLineGameFragment = {
  home: MatchActorFragment, 
  away: MatchActorFragment, 
  roundName: {},
  arena:{},
  division: {
    stage: {
      category: {}
    }
  },
  referees:[{
    referee: {}
  }],
  video: {}
};

function TimelineGame ({matchlink, locationName, walkover} : {matchlink: MatchLink, locationName:string, walkover:boolean}) {
  const T = useTranslations('cmresults');
  const match = useLink(matchlink, TimeLineGameFragment);

  const colors = useColors();
  const t = useCurrentTournament();

  const colorString = walkover ? "#999" : getColorString(getEventPlaceBg(colors,'arena'));

  return <Link to={`/match/${match.id}`} className={classNames(styles.match_link, false && match.finished && styles.finished, match.finished && 'finished', !match.finished && 'unfinished')} style={{'--clr-border': colorString} as any}>
     {walkover && <div className={styles.match_wo}>
        <b>{T('WO')}</b>: {T('The game is marked as Walk-over and will not be played!')}
    </div>}
    <div className={styles.match_info_container}>
      <div className={styles.teams_class}>
        <div className={styles.teams}>
          <div className={styles.team}>
            {match.home.team && <Flag code={match.home.team.club.nation.code} alt={T.select(match.home.team.club.nation.name)} teamId={match.home.id} />} <MiddleEllipsisSpan text={T.select(match.home.name)}/>
          </div>
          <div className={styles.team}>
            {match.away.team && <Flag code={match.away.team.club.nation.code} alt={T.select(match.away.team.club.nation.name)} teamId={match.away.id} />} <MiddleEllipsisSpan text={T.select(match.away.name)}/>
          </div>
        </div>
        <div className={styles.class}>
          <span>
            <span>
              {match.division.__typename == "Playoff" ? 
              (<>
                <span>
                  <FontAwesomeIcon icon={faTrophy}/>
                    {T.select(match.division.stage.name)}
                </span> - <span>{T.select(match.roundName.name)}</span>
              </>) : 
              match.division.stage.type === "conference" ?
                <span><FontAwesomeIcon icon={faTrophy}/>{T.select(match.division.name)}</span> :
                <><span><FontAwesomeIcon icon={faTrophy}/>{T.select(match.division.stage.name)}</span> -  <span>{T.select(match.division.name)}</span></>
            }</span>   
          </span>
        </div>
      </div>
    </div>
    <div className={styles.match_info} style={{background: colorString}}>
      <FieldName locationName={locationName} arena={match.arena}/>
      {match.video?.externalLink && <picture>
        <source
          srcSet='https://static.cupmanager.net/images/cm-results/solid-sport-white.svg' 
          media="(min-width:576px)"
        />
        <img src='https://static.cupmanager.net/images/cm-results/solid-sport-white-icon.svg' alt={T('Stream')} />
      </picture>}

      {match.live && <img src='https://static.cupmanager.net/images/cm-results/live-report_light.svg' alt="" />}
      
    </div>
  </Link>
}

function TimelineRefereeGame ({event, locationName, referee} : {event: Team$MatchEvent, locationName:string, referee:  Referee}) {
  const _t = useCurrentTournament();
 
  const T = useTranslations('cmresults');
  const match = useLink(event.match, TimeLineGameFragment);
  const me = useMe({optionalCupId: _t.cup.id},{});
  const refereeKey = new URLSearchParams(window.location.search).get("refereeKey");

  const hasAccess = refereeKey || (me.referees?.ids && (me.referees.ids.indexOf(referee.id)  > -1));
  const meReferee = useLink(hasAccess ? referee.me : undefined, {
    reviews:[{}],
    coachReviews:[{referee:{}}]
  }, {
    networkParams: {auth:refereeKey}
  });

  const review = meReferee?.reviews.find(review => review.match.href === event.match.href);
  const coachReviews = meReferee?.coachReviews.filter(review => review.match.href === event.match.href);

  const colors = useColors();
  const t = useCurrentTournament();

  const useRefereeReports = match.division.stage.category.useRefereeReports;

  const colorString = event.isWalkOver ? "#999" : getColorString(getEventPlaceBg(colors,'arena'));

  return <div x-to={`/match/${match.id}`} className={classNames(styles.match_link, false && match.finished && styles.finished, match.finished && 'finished', !match.finished && 'unfinished')} style={{'--clr-border': getColorString(getEventPlaceBg(colors,'arena'))} as any}>
    <div className={styles.referee_info} style={{background: colorString}}>
      <span className={styles.referee_position_label}>{T.select(match.referees?.find(r => r.referee.id === referee.id)?.positionName)}</span>
      <span>-</span>
      <span className={styles.gamenbr_label}>{T.select("Game #:")}</span>
      <span>{match.matchNr}</span>
    </div>
    {event.isWalkOver && <div className={styles.match_wo}>
        <b>{T('WO')}</b>: {T('The game is marked as Walk-over and will not be played!')}
    </div>}
    <div className={styles.match_info_container}>
      <div className={styles.teams_class}>
        <div className={styles.teams}>
          <div className={styles.team}>
            {match.home.team && <Flag code={match.home.team.club.nation.code} alt={T.select(match.home.team.club.nation.name)} teamId={match.home.id} />} <MiddleEllipsisSpan text={T.select(match.home.name)}/>
          </div>
          <div className={styles.team}>
            {match.away.team && <Flag code={match.away.team.club.nation.code} alt={T.select(match.away.team.club.nation.name)} teamId={match.away.id} />} <MiddleEllipsisSpan text={T.select(match.away.name)}/>
          </div>
        </div>
        <div className={styles.class}>
          <span>
            <span>
              {match.division.__typename == "Playoff" ? 
              (<>
                <span>
                  <FontAwesomeIcon icon={faTrophy}/>
                    {match.division.stage.category.shortName + ' - '}
                    {T.select(match.division.stage.name)}
                </span> - <span>{T.select(match.roundName.name)}</span>
              </>) : 
              match.division.stage.type === "conference" ?
                <span><FontAwesomeIcon icon={faTrophy}/>{match.division.stage.category.shortName + ' - '}{T.select(match.division.name)}</span> :
                <><span><FontAwesomeIcon icon={faTrophy}/>{match.division.stage.category.shortName + ' - '}{T.select(match.division.stage.name)}</span> -  <span>{T.select(match.division.name)}</span></>
            }</span> 
              {useRefereeReports && event.refereeReportUrl && <span className={styles.report_span}>
                <LinkButton external className={classNames(styles.report_button)} size='sm' 
                    url={event.refereeReportUrl}>
                    {T('Upload reports')}
                  </LinkButton>
              </span>}
          </span>
        
        </div>
      </div>
    </div>
    {Array.isArray(match.referees) && match.referees.length > 1 && <div className={styles.referees}>
      {match.referees.map((ref) => ref.referee.id !== referee.id &&  <div className={classNames(styles.referee, ref.referee.id === referee.id && styles.you)} key={ref.referee.id}>
        <div className={styles.referee_name_position}>
          <div className={styles.referee_position}>{T.select(ref.positionName)}</div>
          <div>{ref.referee.name}</div>
        </div>
        <div>
          { review?.coach?.href === ref.referee.href && review.approved && review.view && 
            <LinkButton external className={classNames(styles.review_button)} size='sm' 
            url={review.view.replace("___REFEREEID___", ""+referee.id).replace("___REFEREEKEY___", meReferee?.key.key || '')}>
              
                {T('Read review')}
            </LinkButton>}
          { coachReviews?.filter(review => review.referee.href === ref.referee.href).map((review, i) =>
              <LinkButton external className={classNames(styles.review_button, styles.coach, review.submitted && styles.submitted, review.reviewed && styles.reviewed)} size='sm' 
                url={review.edit.replace("___REFEREEID___", ""+referee.id).replace("___REFEREEKEY___", meReferee?.key.key || '')} key={i}>
                {review.submitted && <FontAwesomeIcon icon={faCircleCheck}/>}
                {review.submitted ? T('Reviewed') : T('Review')}
              </LinkButton>
          )}
        </div>
      </div>)}
    </div>}
    <div className={styles.match_info} style={{background: colorString}}>
      <FieldName locationName={locationName} arena={match.arena}/>
      {match.video?.externalLink && <picture>
        <source
          srcSet='https://static.cupmanager.net/images/cm-results/solid-sport-white.svg' 
          media="(min-width:576px)"
        />
        <img src='https://static.cupmanager.net/images/cm-results/solid-sport-white-icon.svg' alt={T('Stream')} />
      </picture>}

      {match.live && <img src='https://static.cupmanager.net/images/cm-results/live-report_light.svg' alt="" />}
      
    </div>
  </div>
}

type lodgingProps = {
  eventData: Team$TimelineEvent
}

function TimelineLodging ({eventData} : lodgingProps) {
  const T = useTranslations('cmresults');

  const getFaIcon = () => {
    if (eventData.type === 'food') {
      return faForkKnife
    }

    return false;
  }

  const icon = getFaIcon();

  const t = useCurrentTournament();
  const formatDate = useFormatDate(t.cup.timeZoneId)
  
  return <div className={classNames(styles.food_arrival, (eventData.type === 'sleep' || eventData.type === 'wake') && styles.lodging)}>

    {icon && <FontAwesomeIcon icon={icon} />}
    {eventData.type === 'food' && <span className={styles.lodging_location}>{T.select(eventData.meal?.name)}</span>}
    {eventData.type === 'sleep' && <span className={styles.lodging_location}>{T('Stay overnight')}</span>}
    {eventData.type === 'wake' && <span className={styles.lodging_location}>{T('Rise and shine!')}</span>}

    {eventData.type === 'food' && <span className={styles.opening_hours}>
      Öppet: {eventData.startTime && formatDate(eventData.startTime, 'HH:mm')} {eventData.endTime && ` - ${formatDate(eventData.endTime, 'HH:mm')}`}
    </span>}
  </div>
}