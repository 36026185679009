import { useTranslations } from "cupman-utils"
import { useParams } from "react-router-dom"
import { Cup, MeReferee, MeReferee$AcceptedDays, Referee, useMe, useReferee } from "../../api/schema";
import App, { useCurrentTournament } from "../../App";
import PageHeader from "../../components/PageHeader/PageHeader"
import TeamTimeLine from "../../components/TeamTimeLine/TeamTimeLine";
import { ErrorBoundary } from "../../Error/ErrorBoundary";
import ErrorComponent from "../../Error/ErrorComponent";
import { useAdmin } from "../../components/AdminBanner/AdminBanner";
import { useFormatDate } from "cupman-utils/lib/LangContext";
import styles from  './RefereePage.module.scss';
import classNames from "classnames";
import { useClient, useLink, useMutation, useNetworkMutationParams, useNetworkParams } from "granular";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faCheck, faCircle, faCircleCheck, faSquare, faSquareCheck, faSquareMinus } from "@fortawesome/pro-regular-svg-icons";
import ModalTrigger from "../../utils/ModalTrigger";
import Button from "../../components/Button/Button";
import { useState } from "react";

export default function  RefereePage(){
  const refereeKey = new URLSearchParams(window.location.search).get("refereeKey");
  
  // const applied1 = useNetworkParams(refereeKey ? {auth: refereeKey} : {apa: "apa"})
  // const applied2 = useNetworkMutationParams(refereeKey ? {auth: refereeKey} : {apa: "apa"})

  return <RefereePage_Inner></RefereePage_Inner>;
}


function RefereePage_Inner() {

  const t = useCurrentTournament();

  const T = useTranslations('cmresults');
  const params = useParams() as {id:string};
  const id = parseInt(params.id)
  const formatDate = useFormatDate(t.cup.timeZoneId);

  const refereeKey = new URLSearchParams(window.location.search).get("refereeKey");

  const admin = useAdmin();

  const referee = useReferee({id: id}, Referee.fragment({
    timeline: TeamTimeLine.fragment,
    days:[{
      day:{},
      matches: [{
        arena: {},
        referees: [{
          referee: {}
        }]
      }]
    }]
  }));
  

  const me = useMe({optionalCupId: t.cup.id}, {});
  const hasAccess = refereeKey || (me.referees?.ids && (me.referees.ids.indexOf(referee.id)  > -1));
  const meReferee = useLink(hasAccess ? referee.me : undefined, {
    timeline: TeamTimeLine.fragment
  }, {
    networkParams: {auth:refereeKey}
  });

  const acceptAssignment = meReferee?.acceptAssignment || false;
  const timeline = meReferee?.timeline || referee.timeline;


  const [accept] = useMutation(
    meReferee?.acceptRefereeMatches, 
    undefined, 
    {networkParams: {auth:refereeKey}}
  );
  const [removeExpense] = useMutation(
    meReferee?.removeRefereeExpense, 
    undefined, 
    {networkParams: {auth:refereeKey}}
  );
  const published = t.refereesPublished || admin.overridePublished;

  var loginUrl = new URL(t.cup.loginUrl);
  loginUrl.searchParams.append("redirectTo", window.location.href);

  return <>
    <PageHeader 
      title={referee.name || ''} 
      crumbs={[{
        title: T('Resultat'),
        url: ''
        }
      ]}
    />

    {published && <div className={"max-50"}>
        <h2 className={classNames(styles.day_table_title, "custom-font")}>
          {T('Day overview')}
        </h2>
        {acceptAssignment && <div className={styles.accept_info}>
          {T.select(meReferee?.acceptInfo)}  
        </div>}
        <div className={styles.day_table}>
          <div className={styles.day_table_header}>
            <div>{T("Date")}</div>
            <div>{T("Games")}</div>
            <div></div>
          </div>
          <div className={styles.day_table_body}>
            {referee.days.map((rd, i) => {

              const hasNotAcceptedMatch = acceptAssignment && rd.matches && !!rd.matches.find(match => meReferee?.acceptedDays.acceptedGames.indexOf(match.id) === -1);

              const checked = acceptAssignment && meReferee && meReferee.acceptedDays.acceptedDays.indexOf(rd.day.id) > -1;

              return <div className={styles.day_table_row} key={i}>
                <div>
                  {formatDate(rd.day.date, "dd MMM")}
                </div>
                <div>
                  {rd.published 
                    ? rd.count /*T("X game(s):X",rd.count)*/ 
                    : T('Not published')
                  }
                </div>
                
                <div>
                { acceptAssignment && meReferee && rd.published && rd.count > 0 && <>
                  <ModalTrigger
                    trigger={doOpen => <>

                      {!checked
                        ? <button 
                          className={classNames(styles.btn_accept, styles.check_ready)} 
                          onClick={doOpen}>
                            <FontAwesomeIcon icon={faSquare} /> {T('Accept assignment')}
                          </button>
                        : hasNotAcceptedMatch
                          ? <button 
                            className={classNames(styles.btn_accept, styles.check_changes)} 
                            onClick={doOpen}>
                              <FontAwesomeIcon icon={faSquareMinus} /> {T('Accept changes')}
                            </button>
                          : <button 
                            disabled={true}
                            className={styles.btn_accept}>
                              <FontAwesomeIcon icon={faSquareCheck} /> {T('Games accepted')}
                            </button>
                      }
                    </>
                    }
                    modal={doClose => <div>
                      <h3 className={styles.days_matches_header}>
                        <FontAwesomeIcon icon={faCalendar}/> {formatDate(rd.day.date, "dd MMMM")}
                        </h3>
                      <div className={styles.matches_grid}>
                        <div className={styles.grid_header}>{T('Start time')}</div>
                        <div className={styles.grid_header}>{T('Field')}</div>
                        <div className={styles.grid_header}>{T('Your pos.')}</div>
                        <div></div>
                        {rd.matches.map(match => {

                          const refPosition = match.referees?.find(ref => ref.referee.id === referee.id)?.positionName;

                          const matchAccepted = meReferee.acceptedDays.acceptedGames.some(game => game === match.id);

                          return <>
                            <div>{formatDate(match.start, 'HH:mm')}</div>
                            <div>{match.arena?.name}</div>
                            <div>{T.select(refPosition)}</div>
                            <div>
                              {matchAccepted && <FontAwesomeIcon icon={faCheck}/>}
                            </div>
                          </>
                        })}
                      </div>

                      <div className={styles.buttons}>
                        <Button inverted onClick={doClose}>{T('Cancel')}</Button> 
                        <Button onClick={() => accept && accept({
                            refereeId: referee.id,
                            dayId: rd.day.id,
                            accept: true
                          }).then(() => {
                            doClose();
                          })}>Accept</Button>
                      </div>

                    </div>}
                  />
                  
                  </>}
                </div>
              </div>
            })}
          </div>
        </div>
        {!hasAccess && <div className={styles.login_info}>
          <span>{T("You do not have access to the detailed functions of X",referee.name)}</span>
          <span><a href={loginUrl.toString()}>
            {T("Log in as X",referee.name)}</a>
          </span>
        </div>}
    </div>}

    {published && meReferee && t.cup.modules.refereePayouts && <ErrorBoundary fallback={({error, errorInfo}) => <div className="max-75" style={{marginTop: '4rem'}}>
      <ErrorComponent error={error} errorInfo={errorInfo} text={T('Could not load referee costs')} />
    </div>}>
        <div className="max-50">
          <h2 className={classNames(styles.day_table_title, "custom-font")}>
            {T('Referee salary')}
          </h2>

          <div className={styles.payout_info}>
            {!meReferee.hasAcceptedCosts && <div>{T.select(meReferee.payoutInfo)}  </div>}

            {!meReferee.refereeCostsPublished && <div className={styles.costs_not_published}>
             {T("The tournament has not published your expected salary yet")}
            </div>}

            {meReferee.isPayoutApproved && <div className={styles.costs_not_published}>
             {T("The tournament has approved your salary")}
            </div>}

            {meReferee.hasAcceptedCosts && !meReferee.isPayoutApproved && <div className={styles.costs_not_published}>
             {T("The tournament is reviewing your salary")}
            </div>}
          </div>

          {meReferee.refereeCostsPublished && <>
            <div >
              <div className={styles.costs_table}>
                <div className={styles.costs_table_header}>
                  <div>{T('Game salaries')}</div>
                  <div>{T('Count')}</div>
                  <div>{T('Sum')}</div>
                </div>
                {meReferee.receipt.costRows.map(row => <div className={styles.costs_table_row}>
                    <div>{row.names.join(', ')}</div>
                    <div className={styles.count}>{row.count}</div>
                    <div className={styles.amount}>{row.amount}</div>
                </div>)}
                <div className={classNames(styles.costs_table_row, styles.total)}>
                  <div>{T('Total:')}</div>
                  <div></div>
                  <div className={styles.amount}>{meReferee.receipt.totalCosts}</div>
                </div>
              </div>
            </div>

            <div >
              <div className={styles.expenses_table}>
                <div className={styles.expenses_table_header}>
                  <div>{T('Extra expenses')}</div>
                  <div>{T('Status')}</div>
                  <div>{T('Amount')}</div>
                  <div></div>
                </div>
                {meReferee.receipt.expenses.map(expense => <div className={classNames(styles.expenses_table_row, expense.status === "Denied" && styles.denied)}>
                    <div className={styles.description}>{expense.description}</div>
                    <div >{expense.status === "Approved" ? T('Approved') :
                           expense.status === "Denied" ? T('Denied') : 
                           T('Pending...')}</div>
                    <div className={styles.amount}>{expense.amount}</div>
                    <div className={styles.remove}>
                      {!meReferee.hasAcceptedCosts && <button onClick={() => removeExpense && removeExpense({expenseId: expense.expenseId, refereeId:meReferee.id})} >{T('Remove')}</button>}
                    </div>
                </div>)}
                <div className={classNames(styles.expenses_table_row, styles.total)}>
                  <div>{T('Total:')}</div>
                  <div></div>
                  <div className={styles.amount}>{meReferee.receipt.totalExpenses}</div>
                </div>
              </div>
            </div>


            <div className={styles.payout_sums}>
              <div><span className={styles.title}>{T('Game Salary')}</span><span className={styles.value}>{meReferee.receipt.totalCosts}</span></div>
              <span className={styles.plus}>+</span>
              <div><span className={styles.title}>{T('Expenses')}</span><span className={styles.value}>{meReferee.receipt.totalExpenses}</span></div>
              <span className={styles.equals}>=</span>
              <div><span className={styles.title}>{T('Total')}</span><span className={styles.value}>{meReferee.receipt.totalCosts + meReferee.receipt.totalExpenses}<span className={styles.currency}>{t.cup.currencyCode}</span></span></div>
            </div>


            {!meReferee.hasAcceptedCosts && <div className={styles.payout_buttons}>
              <ModalTrigger
                trigger={doOpen =>  <button disabled={meReferee.hasAcceptedCosts} onClick={doOpen} className={classNames("btn", styles.add_expense)}>{T('Add expense')}</button>}
                modal= {doClose => <AddExpenseModal meReferee={meReferee} onClose={doClose}/>}
              />

              <ModalTrigger
                trigger={doOpen =>  <button disabled={meReferee.hasAcceptedCosts} onClick={doOpen} className={classNames("btn", styles.accept_costs)}>{T('Accept salary')}</button>}
                modal= {doClose => <AcceptCostsModal meReferee={meReferee} onClose={doClose}/>}
              />
            </div>}

            {meReferee.hasAcceptedCosts && !meReferee.isPayoutApproved && <div className={styles.payout_info}>
              <div className={styles.costs_not_published}>
                {T("The tournament is reviewing your salary")}
              </div>
            </div>}
          </>}

        </div>
    </ErrorBoundary>}
    



    {published && <ErrorBoundary fallback={({error, errorInfo}) => <div className="max-75" style={{marginTop: '4rem'}}>
      <ErrorComponent error={error} errorInfo={errorInfo} text={T('Could not load timeline')} />
    </div>}>
      <TeamTimeLine entity={referee} timeline={timeline} scrollToUnfinished={false} />
    </ErrorBoundary>}
  </>
}


function AcceptCostsModal({onClose,meReferee}:{onClose:()=>void, meReferee: MeReferee}) {
  const T = useTranslations("cmresults");
  const t = useCurrentTournament();
  
  const refereeKey = new URLSearchParams(window.location.search).get("refereeKey");

  const [accept] =  useMutation(meReferee.acceptRefereeCosts, 
    undefined, 
    {networkParams: {auth:refereeKey}}
  );

  return <div className={styles.expense_modal}>
    <h3 className={styles.days_matches_header}>
      {T('Accept salary')}
    </h3>

    <div className={styles.payout_sums}>
       <div><span className={styles.title}>{T('Total')}</span><span className={styles.value}>{meReferee.receipt.totalCosts + meReferee.receipt.totalExpenses}<span className={styles.currency}>{t.cup.currencyCode}</span></span></div>
    </div>

    <div className={styles.payout_info}>
      {T('Accept the calculated salary. After you have accepted the salary, you cannot add additional expenses. All expenses need to be approved by the tournament')}
    </div>

    <div className={styles.buttons}>
      <Button inverted onClick={onClose}>{T('Cancel')}</Button> 
      <Button onClick={() => accept({acceptedAmount:meReferee.receipt.totalCosts, refereeId:meReferee.id}).then(onClose)}>{T('Accept salary')}</Button>
    </div>
  </div>
}

function AddExpenseModal({onClose,meReferee}:{onClose:()=>void, meReferee: MeReferee}) {
  const T = useTranslations("cmresults");
  const t = useCurrentTournament();

  const refereeKey = new URLSearchParams(window.location.search).get("refereeKey");

  const [add] =  useMutation(meReferee.addRefereeExpense, 
    undefined, 
    {networkParams: {auth:refereeKey}}
  );

  const [amount,setAmount] = useState(0);
  const [description,setDescription] = useState('');
  const [selectedFile, setSelectedFile] = useState({id:0, publicUrl:""});
  const [uploadProgress, setUploadProgress] = useState<number|null|"Error">(null);

  function startUpload(file:File, inputField:HTMLInputElement) {
    inputField.value = ""; // Reset

    var xhr = new XMLHttpRequest();
    var fd = new FormData();
    fd.append("file", file);

    xhr.upload.addEventListener("progress", function(e) {
      if (e.lengthComputable) {
        var percentComplete = Math.round(e.loaded * 100 / e.total);
        setUploadProgress(percentComplete);
      } else {
        setUploadProgress(50);
      }
    }, false);
    xhr.addEventListener("load", function(e) {
      try {
        var file = JSON.parse(xhr.responseText) as {id:number, publicUrl:string};
        setSelectedFile(file);
        setUploadProgress(null);
      } catch(e) {
        console.log("could not parse file upload response", xhr.responseText);
      }
    }, false);

    xhr.addEventListener("error", function() {
            setUploadProgress("Error");
        }, false);

    xhr.open("POST", "/rest/results_api/uploadRefereePayoutAttachment/" + meReferee.id);
    xhr.send(fd);
  }

  return <div className={styles.expense_modal}>
    <h3 className={styles.days_matches_header}>
      {T('Add expense')}
    </h3>
    
    <h4>{T('Description')}</h4>
    <textarea className={styles.expense_description} value={description} onChange={e => setDescription(e.target.value)}/>

    <h4>{T('Expense in X', t.cup.currencyCode.toUpperCase())}</h4>
    <input className={styles.expense_amount} type="number" value={amount} onChange={e => setAmount(parseInt(e.target.value))}/>

    <h4>{T('Attachment')}</h4>
    <div className={styles.expense_upload} >
        {typeof uploadProgress !== "number" && selectedFile.id === 0
        ? <>
            <label className={"btn sm"}>
                <input type="file"  onChange={(ev) => ev.target.files && startUpload(ev.target.files[0], ev.target) } />
                {T('Upload attachment')}
            </label>
        </> : selectedFile.id > 0 ? <>
            {T('Attachment uploaded')}
        </> : <>
            {T('Uploading...')} {uploadProgress}%
        </>}
    </div>

    <div className={styles.buttons}>
      <Button inverted onClick={onClose}>{T('Cancel')}</Button> 
      <Button disabled={amount === 0 || description.length === 0 || !selectedFile} onClick={() => add({amount,attachmentId:selectedFile.id,description,refereeId:meReferee.id}).then(onClose)}>{T('Save expense')}</Button>
    </div>
  </div>
}

