import { useTranslations } from "cupman-utils";
import { useLink } from "granular";
import { useCurrentTournament } from "../../App";
import PageHeader from "../../components/PageHeader/PageHeader";
import TopScrollShadow from "../../components/TopScrollShadow/TopScrollShadow";
import { Suspense, useMemo, useState } from "react";
import { useFormatDate } from "cupman-utils/lib/LangContext";
import FilterButtonMenuV2 from "../../components/FilterButtonMenu/FilterButtonMenuV2";
import styles from './GamesPage.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullseye, faBullseyeArrow, faCircleExclamation } from "@fortawesome/pro-solid-svg-icons";
import { useMatchWindow } from "../../api/schema";
import MatchTable, { TableRow } from "../../components/MatchTable/MatchTable";
import ContentLoader from "react-content-loader";
import classNames from "classnames";
import React from "react";
import Button from "../../components/Button/Button";
import { useAdmin } from "../../components/AdminBanner/AdminBanner";
import { useLocation } from "react-router-dom";

type MatchWindowData = {
  windowIndex: number,
  matchIds: number[]
}

function makeMatchWindows(matches: {
  matchId: number;
  index: number;
}[], windowLimit: number,
matchLimit: number) : MatchWindowData[] {

  
  
  const windows : MatchWindowData[] = [];
  let currentWindow : MatchWindowData|null = null;
  let matchCount = 0;

  for (let m of matches) {

    const windowIndex = Math.floor(m.index / windowLimit);

    if(currentWindow === null || windowIndex > currentWindow.windowIndex) {
      currentWindow = {
        windowIndex: windowIndex,
        matchIds: []
      };
      windows.push(currentWindow);
    }
    // garanterat att currentWidnow är rätt för windowIndex, oavsett om det är nytt eller gammalt
    currentWindow.matchIds.push(m.matchId);
    matchCount++

    if(matchCount === matchLimit) {
      break;
    }
  }

  return windows;
}


const WINDOW_SIZE = 35;
const PAGE_SIZE = 100;

export default function GamesPage (){

  const location = useLocation();
  const paramClass = new URLSearchParams(location.search);
  const params = {
    days: paramClass.get('day')?.split(',') || [],
    arenas: paramClass.get('arena')?.split(',') || [],
    categories: paramClass.get('category')?.split(',') || [],
    stages: paramClass.get('stage')?.split(',') || []
  }

  const T = useTranslations('cmresults');
  const t = useCurrentTournament();

  const {overridePublished} = useAdmin();

  const tournamentExpanded = useLink(t, {
    matchData: [],
    lotCategories: [{
      stages: [{}]
    }],
    arenas: [{}],
    days: [{}]
  });


  const format = useFormatDate();

  /* FILTERS */
  const [arenasFilter, setArenasFilter] = useState(() => tournamentExpanded.arenas.reduce((idsSelected, arena) => {

    if(params.arenas.length > 0) {

      return {
        ...idsSelected,
        [arena.id] : params.arenas.indexOf(arena.id.toString()) > -1 ? true : false,
        0 : false // Unscheduled ones
      }
    }

    return {
      ...idsSelected,
      [arena.id] : true,
      0 : true // Unscheduled ones
    }
  }, {} as {[x:number] : boolean}));

  const [daysFilter, setDaysFilter] = useState(() => Object.fromEntries(tournamentExpanded.days.map((day) => {
    if(params.days.length > 0) {
      return [
        day.index, params.days.some(_day => parseInt(_day) === day.index)
      ]
    }

    return [
      day.index, true
    ]
  })));


  const [categoriesFilter, setCategoriesFilter] = useState(() => Object.fromEntries(tournamentExpanded.lotCategories.map(category => {

    if(params.categories.length > 0) {
      return [
        category.id, params.categories.some(cat => cat === category.id.toString())
      ]
    }
    return [
      category.id, true
    ]
  })));

  const [stagesFilter, setStagesFilter] = useState(() => {
    const newArr = [...new Set(
      tournamentExpanded.lotCategories.map(category => 
        category.stages.map(stage => stage.name)).flat()
    )]

    if (params.stages.length > 0) {
      return Object.fromEntries(
        newArr.map(stageName => [stageName, params.stages.some(stage => stage === stageName)])
      ) 
    }

    return Object.fromEntries(
      newArr.map(stageName => [stageName, true])
    )
  });



  const matchesArr = tournamentExpanded.matchData;

  const filteredMatchArray = useMemo(()=> {
    return matchesArr.filter(match => {

      const filteredArenaIds = [
        ...Object.entries(arenasFilter).filter(arenaArr => arenaArr[1]).map(arenaArr => parseInt(arenaArr[0]))
    ]

      const filteredDayIds = Object.entries(daysFilter).filter((dayArr, i) => {
        const dayIndex = parseInt(dayArr[0]);

        const scheduled = tournamentExpanded.days.find(day => day.index === dayIndex)?.schedulePublished || overridePublished;
          return scheduled && dayArr[1]
      }).map(dayArr => parseInt(dayArr[0]));
      

      const filteredCategoryIds = Object.entries(categoriesFilter).filter(categoryArr => {
        const scheduled = tournamentExpanded.lotCategories.find(category => category.id === parseInt(categoryArr[0]))?.schedulePublished || overridePublished;
          return scheduled && categoryArr[1]
      }).map(categoryArr => parseInt(categoryArr[0]))
      
      
      const filteredStageNames = Object.entries(stagesFilter).filter(stagesArr => {
        return stagesArr[1]
      }).map(stagesArr => stagesArr[0])
      
      const cat = tournamentExpanded.lotCategories.find(cat => cat.id === match[2])!;
      const catStage = cat.stages.find(stage => stage.id === match[3])!;
      const stageName = catStage.name;
  
      const existsInFilters = filteredArenaIds.includes(match[4]) &&
                              filteredDayIds.includes(match[1]) &&
                              filteredCategoryIds.includes(match[2]) &&
                              filteredStageNames.includes(stageName) && 
                              (catStage.schedulePublished || overridePublished)
  
      return existsInFilters

    }).map(match => ({
      matchId: match[0],
      index: matchesArr.indexOf(match)
    }));
  }, [arenasFilter, categoriesFilter, daysFilter, matchesArr, overridePublished, stagesFilter, tournamentExpanded.days, tournamentExpanded.lotCategories]);

  const [matchAmountToShow, setMatchAmountToShow] = useState(PAGE_SIZE)

  const matchWindows = useMemo(() => {
    return makeMatchWindows(filteredMatchArray, WINDOW_SIZE, matchAmountToShow)
  }, [filteredMatchArray, matchAmountToShow])

  const arenas = tournamentExpanded.arenas.map(arena => ({key: arena.id, label: arena.name})).sort((a, b) => a.label.localeCompare(b.label))
  

  


  return <div>
    <PageHeader 
      title={T('Games')} 
      subtitle={T('Visar X av Y st', filteredMatchArray.length, matchesArr.length)}
      scrolledTitle={T('Matcher (X st)', filteredMatchArray.length)}
      crumbs={[{
        title: T('Results'),
        url: ''
        }
      ]}
    >
      <div className="max-75">
       <div className={styles.filter_buttons_container}>
        <FilterButtonMenuV2 
          htmlForPrefix="day_"
          label={T('dagar')}
          items={tournamentExpanded.days.map((day) => ({
            key: day.index, 
            label: format(day.date, 'EEEE dd/MM'), 
            published: !(day.schedulePublished || overridePublished)
          }))}
          selected={daysFilter}
          setSelected={items => {
            setMatchAmountToShow(PAGE_SIZE)
            setDaysFilter(items)
          }}
        />
        <FilterButtonMenuV2 
          htmlForPrefix="cat_"
          label={T('klasser')}
          items={tournamentExpanded.lotCategories.map(category => ({
            key: category.id, 
            label: category.name,
            published: !(category.schedulePublished || overridePublished)
          }))}
          selected={categoriesFilter}
          setSelected={items => {
            setMatchAmountToShow(PAGE_SIZE)
            setCategoriesFilter(items)
          }}
        />
        <FilterButtonMenuV2 
          htmlForPrefix="arena_"
          label={T('spelfält')}
          items={
            [
              {key: 0, label: T('Ej schemalagda')},
              ...arenas
            ]
          }
          selected={arenasFilter}
          setSelected={items => {
            setMatchAmountToShow(PAGE_SIZE);
            setArenasFilter(items);
          }}
        />
        <FilterButtonMenuV2 
          htmlForPrefix="stage_"
          label={T('stadier')}
          items={[...new Set(
            tournamentExpanded.lotCategories.map(category => 
              category.stages.map(stage => stage.name)).flat()
          )].map(stageName => ({
            key: stageName, 
            published: !(
              tournamentExpanded.lotCategories.map(cat => cat.stages.filter(stage => stage.name === stageName)).flat()
                .some(stage => stage.schedulePublished) || overridePublished
            ),
            label: stageName}))}
          selected={stagesFilter}
          setSelected={items => {
            setMatchAmountToShow(PAGE_SIZE)
            setStagesFilter(items)
          }}
        />
      </div>
      </div>
    </PageHeader>

    {tournamentExpanded.schedulePublished 
        ? <>
            <div className={classNames("max-75", styles.wrapper)} style={{position: 'relative'}}>
              {matchWindows.length > 0
                ? <>
                  <MatchTable>
                    {matchWindows.map(window => {

                      const rowHeight = 32;
                      const offsetY = 8;

                      return <React.Fragment key={window.windowIndex}>
                        <Suspense fallback={
                          <tr style={{border: 0, background: 'transparent'}}>
                            <td colSpan={100} style={{padding: 0}}>
                              <ContentLoader
                                width='100%'
                                height={window.matchIds.length * rowHeight}
                              >
                                {Array.from({length: window.matchIds.length}, (_, index) => index).map((_) => {

                                  const itemOffsetY = _ * rowHeight + offsetY;
                                  const roundness = 6;
                                  const height = 12;

                                  return  <React.Fragment key={_}>
                                    <rect y={itemOffsetY} rx={roundness} ry={roundness} x={8} width={54} height={height} /> 
                                    <rect y={itemOffsetY} rx={roundness} ry={roundness} x={87} width={95} height={height} /> 
                                    <rect y={itemOffsetY} rx={roundness} ry={roundness} x={214} width={43} height={height} /> 
                                    <rect y={itemOffsetY} rx={roundness} ry={roundness} x={294} width={144} height={height} />
                                    <rect y={itemOffsetY} rx={roundness} ry={roundness} x={480} width={45} height={height} />
                                    <rect y={itemOffsetY} rx={roundness} ry={roundness} x={550} width={60} height={height} />
                                    <rect y={itemOffsetY} rx={roundness} ry={roundness} x={698} width={124} height={height} />
                                    <rect y={itemOffsetY} rx={roundness} ry={roundness} x={825} width={12} height={height} />
                                    <rect y={itemOffsetY} rx={roundness} ry={roundness} x={848} width={36} height={height} />
                                    <rect y={itemOffsetY} rx={roundness} ry={roundness} x={894} width={12} height={height} />
                                    <rect y={itemOffsetY} rx={roundness} ry={roundness} x={910} width={122} height={height} />
                                    <rect y={itemOffsetY -2} rx={20} ry={20} x={1109} width={height + 7} height={height + 6} />
                                  </React.Fragment>
                                })}

                              </ContentLoader>
                            </td>
                          </tr>
                        }>
                          <MatchesWindow 
                            limit={WINDOW_SIZE} 
                            offsetIndex={window.windowIndex} 
                            matchIds={window.matchIds} tournamentId={t.id}
                          />
                        </Suspense>
                      </React.Fragment>
                    })}
                  </MatchTable>
                  {filteredMatchArray.length >= matchAmountToShow 
                    ? <div className={styles.button_wrapper}>
                      <Button onClick={() => setMatchAmountToShow(amount => amount + PAGE_SIZE)}>
                        + {T('Ladda fler matcher')}
                      </Button>
                    </div>
                    : <div className={styles.info}>
                      <FontAwesomeIcon icon={faCircleExclamation} /> {T('Alla matcher visas som stämmer med filtret')}
                    </div>
                  }
                </>
                : <div className={styles.info}>
                  <FontAwesomeIcon icon={faCircleExclamation} /> {T('Inga matcher överensstämmer')}
                </div>
              }
            </div>
        </> 
        : <div className="max-75">
          <h2><FontAwesomeIcon icon={faCircleExclamation}/> {T('Inga matcher publiserade än')}</h2>
          <p>{T('Kom tillbaka senare eller kontakta turneringen för mer information.')}</p>
        </div>
      }
    
  </div>
}

function MatchesWindow ({limit, offsetIndex, matchIds, tournamentId} : {
  limit: number, 
  offsetIndex: number, 
  matchIds: number[],
  tournamentId: number
}) {


  const realMatches = useMatchWindow({
    tournamentId: tournamentId,
    limit: limit,
    offset: limit * offsetIndex
  }, {
    matches: [MatchTable.fragment]
  })

  const matchesToRender = realMatches.matches.filter(match => matchIds.some(matchId => matchId === match.id))

  return <>{matchesToRender.map(match => <TableRow match={match} key={match.id}/>)}</>
}