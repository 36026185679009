type props = {
  icon: string,
  fontWeight?: 'thin' | 'regular' | 'bold',
  white?: boolean
}

export default function CustomIconFa ({icon, fontWeight, white} : props) {

  const iconUrl = `https://static.cupmanager.net/images/icons_svg/${white ? 'white/' : ''}${!!fontWeight ? fontWeight : 'regular'}/${icon}.svg`

  return (
    <img src={iconUrl} alt="" aria-hidden="true" />
  )
}