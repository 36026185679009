import { Sport$SportTypes, Team$shirt as Shirt } from "../../api/schema";
import DressBasket from "../Dress/DressBasket";
import DressHockey from "../Dress/DressHockey";
import DressSoccer from "../Dress/DressSoccer";

type props = {
  sport?: Sport$SportTypes,
  dress?: Omit<Shirt,'__typename'>,
  nationCode?: string | undefined,
  className?: string
}

export default function Dress ({sport, dress, nationCode, className} : props) {

  const getShirtColors = () => {
    const colorArr = [];

    if(dress?.color1) {
      colorArr.push(dress?.color1)
    } else {
      colorArr.push('#d5d5d5')
    }

    if(dress?.color2) {
      colorArr.push(dress?.color2)
    } else {
      colorArr.push('#b6b6b6')
    }

    if(dress?.color3) {
      colorArr.push(dress?.color3)
    }

    return colorArr;
  }

  return  <>
    {sport === 'basketball' 
      ? <DressBasket
        primary={getShirtColors()[0]} 
        secondary={getShirtColors()[1]}
        tertiary={getShirtColors()[2]} 
        type={dress ? dress.type : 'unknown'}
        code={nationCode}
        className={className}
      />
      : sport === 'icehockey' 
        ? <DressHockey 
          primary={getShirtColors()[0]} 
          secondary={getShirtColors()[1]}
          tertiary={getShirtColors()[2]}
          type={dress ? dress.type : 'unknown'}
          code={nationCode}
          className={className}
        /> 
        :  <DressSoccer 
          primary={getShirtColors()[0]} 
          secondary={getShirtColors()[1]}
          tertiary={getShirtColors()[2]}
          type={dress ? dress.type : 'unknown'}
          code={nationCode}
          className={className}
        />
    }
  </>
}