import { useTranslations } from "cupman-utils";
import { useFormatDate } from "cupman-utils/lib/LangContext";
import { useLink } from "granular";
import { Link, useParams } from "react-router-dom";
import { EditionDay, Match, Team$matches, useMe } from "../../api/schema";
import { useCurrentTournament } from "../../App";
import { useAdmin } from "../../components/AdminBanner/AdminBanner";
import Games from "../../components/Game/Games";
import PageHeader from "../../components/PageHeader/PageHeader";
import Shortcuts from "../../components/Shortcuts/Shortcuts";
import styles from './DayMatchesPage.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/pro-regular-svg-icons";
import classNames from "classnames";
import Flag from "../../components/Flag/Flag";
import { useEffect, useState } from "react";
import { FilterButtonMenu } from "../../components/FilterButtonMenu/FilterButtonMenu";
import { faArrowRight, faEye } from "@fortawesome/pro-solid-svg-icons";

export default function DayMatchesPage () {

  const T = useTranslations('cmresults');
  const t = useCurrentTournament();
  const formatDate = useFormatDate(t.cup.timeZoneId);

  const days = t.days.filter(d => d.playDay);
 
  const params = useParams<{id:string}>();
  const dayId = parseInt(params.id || '0') || days[0].id;
  
  const day = days.find(d => d.id === dayId) as EditionDay;

  const initDayMatches = useLink(day, {matches:[Games.GameFragment]})?.matches;

  const tournamentExpaned = useLink(t, {
    lotCategories: [{
      stages: [{}]
    }],
    arenas: [{}]
  });



  const [filterGameFields, setFilterGameFields] = useState(tournamentExpaned.arenas.reduce((entities, entity) => {
    return {
      ...entities,
      [entity.name||'unscheduled'] : true
    }
  }, {}));


  const allRounds = [...new Set(tournamentExpaned.lotCategories.flatMap(cat => 
    cat.stages.map(stage => stage.name)
  ))];

  const [filterRounds, setFilterRounds] = useState(allRounds.reduce((entities, entity) => {
    return {
      ...entities,
      [entity] : true
    }
  }, {}));

  const [filterCategories, setFilterCategories] = useState(tournamentExpaned.lotCategories.reduce((entities, entity) => {
    return {
      ...entities,
      [entity.name||'unscheduled'] : true
    }
  }, {}));

  const gamesCategories = initDayMatches.map(day => day.division.category.name);
  const gamesArenas = initDayMatches.map(day => day.arena ? day.arena.name : 'unscheduled');
  const gamesRounds = initDayMatches.map(day => day.division.stage.name);


  const newFieldsFilter = Object.entries(filterGameFields).filter(entry => entry[1]).map(entry => entry[0]);
  const newRoundsFilter = Object.entries(filterRounds).filter(entry => entry[1]).map(entry => entry[0]);
  const newCategoriesFilter = Object.entries(filterCategories).filter(entry => entry[1]).map(entry => entry[0]);

  const dayMatches = initDayMatches
  .filter(match => newFieldsFilter.indexOf(match.arena ? match.arena.name : '') > -1)
  .filter(match => newRoundsFilter.indexOf(match.division.stage.name) > -1)
  .filter(match => newCategoriesFilter.indexOf(match.division.category.name) > -1)


  return <>
    <PageHeader 
      title={T('Games on X', formatDate(day?.date || 0, 'EEEE dd/MM'))}
      shortTitle={formatDate(day?.date || 0, 'EEEE dd/MM')}
      crumbs={[{
        title: T('Results'),
        url: ''
        }
      ]}
     
    >
      <Shortcuts links={
      days.map(d => {
        return {
          to: `/days/${d.id}`,
          title: formatDate(d.date, 'EE dd/MM')
        }
      })
    }/>
  </PageHeader>
  <div className={classNames("max-75", styles.matchtable_header)}>
    <h2 className="visually-hidden">{T('Matchtabell')}</h2>
    <div className={styles.filter_container}>
      <h3 style={{marginBottom: 0}}>
        <FontAwesomeIcon icon={faFilter}/> {T('Filtrera')}
      </h3>
      <div className={styles.filters}>
        <FilterButtonMenu 
          checkValues={filterRounds} 
          setValues={(rounds: {[key:string] : boolean}) => setFilterRounds(rounds)} 
          label={T('Omgångar')}
          compareValues={gamesRounds}
        />
        <FilterButtonMenu 
          checkValues={filterCategories} 
          setValues={(categories: {[key:string] : boolean}) => setFilterCategories(categories)} 
          label={T('Klasser')}
          compareValues={gamesCategories}
        />
        <FilterButtonMenu 
          checkValues={filterGameFields} 
          setValues={(fields: {[key:string] : boolean}) => setFilterGameFields(fields)}
          label={T('Spelfält')} 
          compareValues={gamesArenas}
        />
      </div>
      <p>
        <FontAwesomeIcon icon={faEye}/> <strong>{dayMatches.length} matcher</strong> <>{dayMatches.length !== initDayMatches.length && <>(av {initDayMatches.length})</>}</>
      </p>
    </div>
  </div>
  <div className={styles.table_overflow}>
      <table className={styles.match_table}>
        <thead>
          <tr>
            <th>{T('Matchnr.')}</th>
            <th>{T('Tid')}</th>
            <th>{T('Spelplan')}</th>
            <th>{T('Klass')}</th>
            <th>{T('Grupp')}</th>
            <th>{T('Omgång')}</th>
            <th className={styles.right}>{T('Hemma')}</th>
            <th className={styles.no_padding}></th>
            <th className={styles.low_padding}></th>
            <th className={styles.no_padding}></th>
            <th>{T('Borta')}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {dayMatches.map(match => {

            const winner = match.result.homeGoals === match.result.awayGoals
              ? undefined
              : match.result.homeGoals > match.result.awayGoals
                ? 'home'
                : 'away'

            return <tr>
              <td>{match.matchNr}</td>
              <td>{formatDate(match.start, 'EEE. dd/MM HH:mm')}</td>
              <td>{match.arena && match.arena.name}</td>
              <td>{match.division.category.name}</td>
              <td>{T.select(match.division.name)}</td>
              <td>{match.division.stage.name}</td>
              <td className={classNames(styles.right, styles.team, winner === 'home' && styles.bold)}>
                {T.select(match.home.name)}
                <Flag code={match.home.team?.club.nation.code} alt={T.select(match.home.team?.club.nation.name) || ''} />
              </td>
              <td className={classNames(styles.no_padding, styles.right, winner === 'home' && styles.bold)}>{match.result.homeGoals}</td>
              <td className={styles.low_padding}>-</td>
              <td className={classNames(styles.no_padding, winner === 'away' && styles.bold)}>{match.result.awayGoals}</td>
              <td className={classNames(styles.team, winner === 'away' && styles.bold)}>
                <Flag code={match.home.team?.club.nation.code} alt={T.select(match.home.team?.club.nation.name) || ''} />
                {T.select(match.away.name)}
              </td>
              <td>
                <Link to={`/match/${match.id}`} className={styles.match_link}>
                  <FontAwesomeIcon icon={faArrowRight} />
                  <span className="visually-hidden">{T('Gå till match: X', `${T.select(match.home.name)} - ${T.select(match.away.name)}`)}</span>
                </Link>
              </td>
            </tr>
          })}
        </tbody>
      </table>
    </div>



    {/* <Games games={dayMatches} order='asc' published={t.schedulePublished} alwaysShow /> */}
  </>
}