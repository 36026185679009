
import { faArrowRight, faCircle, faQuestionCircle, faToggleOff } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useTranslations } from 'cupman-utils';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Division$table, Stage, StageTeamConference$jumboTable, Team$TeamName } from '../../api/schema';
import { getColorString, useColors } from '../../colors';
import Flag from '../Flag/Flag';
import MiddleEllipsisSpan from '../MiddleEllipsisSpan/MIddleEllipsisSpan';
import styles from './Conference.module.scss';
import { faToggleOn } from '@fortawesome/pro-regular-svg-icons';
import ToggleButtons from '../Button/ToggleButtons';
import Button from '../Button/Button';
import { capitalize } from '../../utils';

Conference.Fragment = {
  rows: [{team: {
    club: {
      nation: {}
    }
  }}]
};

type props = {
  table?: Division$table<typeof Conference.Fragment>,
  name?: string,
  id?: number,
  hideResult?: boolean,
  matchesCanTie: boolean,
  matchAmount?: number,
  className?: string,
  doNotShowImBtn?: boolean,
  linkTeams: boolean,
  nextStages?: Stage[],
  highlightTargetStage?: Stage,
  jumboTable?: StageTeamConference$jumboTable,
  resultPerMatch?: boolean;
}

export default function Conference({table, jumboTable, resultPerMatch, name, id, linkTeams, hideResult, matchAmount, className, nextStages, highlightTargetStage, matchesCanTie, doNotShowImBtn} : props) {
  


  const T = useTranslations('cmresults')
  const _colors = useColors();
  const colors = [_colors.primary,..._colors.complements];
  const light_colors = [_colors.light.primary,..._colors.light.complements];

  const _table = table?.rows;

  const [showIm, setShowIm] = useState(false);
  const groupData = 
    !showIm
      ? // Normal
        _table?.map(row => ({
          code: row.team ? row.team.club.nation.code : undefined,
          nationName: row.team ? row.team.club.nation.name : undefined,
          isIm: row.isIm,
          name: row.name,
          won: row.won,
          lost: row.lost,
          tied: row.tied,
    
          played: row.played,
          goalsWon: row.goalsWon,
          goalsLost: row.goalsLost,
          points: row.points,
          listIm: false,
          team: row.team, 
          targetStage : row.targetStage,
          targetStageTeamConference: row.targetStageTeamConference
        }))
      : // IM
      _table?.map(row => (
          row.isIm
            ? {
              code: row.team ? row.team.club.nation.code : undefined,
              nationName: row.team ? row.team.club.nation.name : undefined,
              isIm: row.isIm,
              name: row.name,
              won: row.im_won,
              lost: row.im_lost,
              tied: row.im_tied,
              played: row.im_played,
              goalsWon: row.im_goalsWon,
              goalsLost: row.im_goalsLost,
              points: row.im_points,
              listIm: true,
              team: row.team,
              targetStage : row.targetStage,
              targetStageTeamConference: row.targetStageTeamConference
            }
            : {
              code: row.team ? row.team.club.nation.code : undefined,
              nationName: row.team ? row.team.club.nation.name : undefined,
              isIm: row.isIm,
              name: row.name,
              won: row.won,
              lost: row.lost,
              tied: row.tied,
              played: row.played,
              goalsWon: row.goalsWon,
              goalsLost: row.goalsLost,
              points: row.points,
              listIm: true,
              team: row.team,
              targetStage : row.targetStage,
              targetStageTeamConference: row.targetStageTeamConference
            }
      ))

  const getDiffStr = (goalsWon:number, goalsLost:number) =>  {
    const onlyDiff = Math.round((goalsWon + goalsLost)*100)/100;
    const onlyDiffStr = () => {
      if(Math.sign(onlyDiff) === 1) {
        return `+${onlyDiff}`
      } else {
        return onlyDiff;
      }
    }

    const diffStr = <>
        <span className={styles.diff_left}>{goalsWon}</span> - <span className={styles.diff_right}>{-goalsLost}</span> <span className={styles.four}>({onlyDiffStr()})</span>
      </>

    return diffStr;
  }
  

  const conferenceContent = !hideResult
  ? <>
      <div>
        <h3>
          {name} {matchAmount && T('- X games', matchAmount)}
        </h3>
      </div>
      <h3>{T('W')}</h3>
      <h3>{matchesCanTie ? T('T') : ''}</h3>
      <h3>{T('L')}</h3>
      <h3>+/-</h3>
      <h3>{T('P')}</h3>
      <h3></h3>
      
      {groupData?.map((team, i) => 
      {
        const cns =  classNames(
            (team.targetStage || team.targetStageTeamConference) && styles.leader, 
            styles.col,
            (!team.isIm && team.listIm) && styles.disabled,
            (team.isIm && team.listIm) && styles.im,
          );

          const targetColor = () => {
            return nextStages && getColorString(colors[nextStages.findIndex(s => s.href === team.targetStage?.href)]);
          }
        return <React.Fragment key={i}>
          <div className={cns} style={nextStages && highlightTargetStage && {background:getColorString(colors[nextStages.findIndex(s => s.href === highlightTargetStage?.href)])}}>
            <div className={classNames(styles.team_name,linkTeams && styles.hover)}>
             {team.code && <Flag code={team.code} alt={team.nationName ? T.select(team.nationName) : ''} className={styles.flag} />} 
             {jumboTable && jumboTable.rows[i].divisionSize > jumboTable.minRank && <span className={styles.jumboAsterix}>*</span>}

              {team.team && linkTeams 
                ? <Link className={styles.teamLink} to={`/team/${team.team.id}`}><MiddleEllipsisSpan inline text={T.select(team.name)}/></Link>
                : <MiddleEllipsisSpan inline text={T.select(team.name)}/>}
            </div>
          </div>
          <span className={cns}>{team.won}</span>
          <span className={cns}>{matchesCanTie ? team.tied : ''}</span>
          <span className={cns}>{team.lost}</span>
          {jumboTable && <>
            <span className={classNames(styles.plus_minus, cns)}>{getDiffStr(team.goalsWon + jumboTable.rows[i].goalsWon, team.goalsLost + jumboTable.rows[i].goalsLost)}</span>
            <span className={cns}>{team.points + jumboTable.rows[i].points}</span>
          </>}
          {resultPerMatch && <>
            <span className={classNames(styles.plus_minus, cns)}>{getDiffStr(
              Math.round(team.goalsWon/team.played*100)/100, 
              Math.round(team.goalsLost/team.played*100)/100)}</span>
            <span className={cns}>{Math.round(team.points/team.played*100)/100}</span>
          </>}
          {!jumboTable && !resultPerMatch && <>
            <span className={classNames(styles.plus_minus, cns)}>{getDiffStr(team.goalsWon, team.goalsLost)}</span>
            <span className={cns}>{team.points}</span>
          </>}
          
          <span className={cns}>
            {nextStages && <>
              {team.targetStage && <>
                <FontAwesomeIcon icon={faArrowRight} color={targetColor()} />
                <span> </span>
                <FontAwesomeIcon icon={faCircle} color={targetColor()} />
              </>}
              {!team.targetStage && team.targetStageTeamConference && <>
                <FontAwesomeIcon color="#999" icon={faArrowRight}/>
                <span> </span>
                <FontAwesomeIcon color="#999" icon={faCircle}/>
              </>}
            </>}
          </span>
        </React.Fragment>
      })}
    </>
  : <>
    <h3 style={{textAlign: 'left', paddingLeft: '1rem'}}>
      {name}
    </h3>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    
    {groupData?.map((team, i) => 
    {
      const listClassNames = () => {
        return classNames(
          styles.col
        )
      }
      return <React.Fragment key={i}>
        <div className={listClassNames()}>
          <div className={styles.team_name}>
            {team.code && <Flag code={team.code} alt={team.nationName ? T.select(team.nationName) : ''} className={styles.flag} />} 
            {team.team && linkTeams 
                ? <Link className={styles.teamLink} to={`/team/${team.team.id}`}><MiddleEllipsisSpan inline text={T.select(team.name)}/></Link>
                : <MiddleEllipsisSpan inline text={T.select(team.name)}/>}
          </div>
        </div>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </React.Fragment>
    })}
  </>

    const imButton = !doNotShowImBtn && !hideResult && groupData?.some(row => row.isIm) && 
      <div className={classNames(styles.imButtonContainer, showIm && styles.showIm)}>
        <button className={styles.imButton} onClick={(event) => {
          setShowIm(!showIm);
          event.preventDefault();
          event.stopPropagation();
        }}>
          {!showIm 
            ? <FontAwesomeIcon icon={faToggleOff} />
            : <FontAwesomeIcon icon={faToggleOn} />
          }
          <span>{T('Internal meetings')}</span>
        </button> 
      </div>

  return (
    <div className={classNames(styles.conference, className)}>
      <div className={styles.scroll_container}>
        <div className={styles.snap_this}></div>

          {id
            ? <Link to={`/division/${id}`} className={styles.group_container}>
              {conferenceContent}
              {imButton}
            </Link>
            : <div className={styles.group_container}>
              {conferenceContent}
              {imButton}
            </div>}
        <div className={styles.snap_this}></div>
      </div>

     
    </div>
  )
}